import { useEffect, useState } from 'react';
import { parseJwt } from '../../utils/common/commonUtils';
import { useMsal } from '@azure/msal-react';
import RootComponent from '../../components/RootComponent';
import SplashScreen from '../../components/common/SplashScreen';
import { useNavigate } from 'react-router-dom';
import Login from './Login';

function Authenticated() {
    const [isValidUser, setValidUser] = useState<boolean | null>(null);
    const { instance } = useMsal();
    const [splashScreen, setSplashScreenOpen] = useState<boolean>(false);
    const navigate = useNavigate(); // Correct useNavigate

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await instance.handleRedirectPromise();

                if (response?.account) {
                    const idToken = response.idToken;
                    const idTokenData = parseJwt(idToken);
                    const loginTime = Date.now().toString();
                    localStorage.setItem("login_time", loginTime);

                    if (idTokenData?.groups?.length > 0) {
                        instance.setActiveAccount(response.account);
                        setValidUser(true);
                        setSplashScreenOpen(true);
                        return;
                    } else {
                        console.warn("User does not have valid group access. Redirecting...");
                        localStorage.clear();
                        sessionStorage.clear();
                        navigate("/InvalidAccess", { replace: true });
                        return;
                    }
                }

                // If no valid session exists, check stored login time
                const storedLoginTime = localStorage.getItem("login_time");
                if (!storedLoginTime) {
                    console.warn("No login time stored. Redirecting to login...");
                    // navigate("/login", { replace: true });
                    setValidUser(false);
                    return;
                }

                const loginTimestamp = parseInt(storedLoginTime, 10);
                const TIME_LIMIT = (23 * 60 * 60 * 1000) + (58 * 1000); // 23 Hours 58 Mins
                const currentTime = Date.now();

                if ((currentTime - loginTimestamp) >= TIME_LIMIT) {
                    console.warn("Session expired. Redirecting to login...");
                    // localStorage.clear();
                    // sessionStorage.clear();
                    // navigate("/login", { replace: true });
                    setValidUser(false);
                    return;
                }

                setValidUser(true);
            } catch (error) {
                console.error("Authentication error:", error);
                setValidUser(false);
                // navigate("/login", { replace: true });
            }
        };

        checkAuthentication();
    }, [instance, navigate]);

    // Prevent rendering before auth check is complete
    if (isValidUser === null) {
        return null;
    }
    // console.log(isValidUser);
    return (
        <>
            {
                isValidUser &&
                <>
                    <SplashScreen isOpen={splashScreen} />
                    <RootComponent />
                </>
            }
            {
                !isValidUser &&
                <Login />
            }
        </>


    );
}

export default Authenticated;
