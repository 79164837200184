import { Navigate, Outlet } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import MsalAuthService, { loginRequest } from './configuration/MsalAuthService';
import { PublicClientApplication } from '@azure/msal-browser';

const AuthGuard = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const [isTokenExpired, setisTokenExpired] = useState(false);

    useEffect(() => {
        // const isTokenExpired = useIsTokenExpired();
        const checkToken = async () => {

            const activeAccount = instance.getActiveAccount();
            if (!activeAccount || !activeAccount.idTokenClaims) {
                setisTokenExpired(true);
                return;
            }
            // const { exp } = activeAccount?.idTokenClaims;
            const claims = activeAccount?.idTokenClaims as { exp?: number };

            if (!claims) {
                setisTokenExpired(true);
                return;
            }

            if (!claims?.exp || claims?.exp === 0) {
                setisTokenExpired(true);
                return;
            }

            const storedLoginTime = localStorage.getItem("login_time");
            const currentTime: number = Date.now();

            if (!storedLoginTime) {
                setisTokenExpired(true);
                return;
            }

            const loginTimestamp = parseInt(storedLoginTime, 10); 
            const TIME_LIMIT: number = (23 * 60 * 60 * 1000) + (58 * 1000); // 23 Hours 58 Mins

            if ((currentTime - loginTimestamp) >= TIME_LIMIT) {
                setisTokenExpired(true);
                return;
            }
        };
        checkToken();

    }, []);

    if (!isAuthenticated || isTokenExpired) {
        return <Navigate to="/login" replace />;
    }
    return <Outlet />;
};

export default AuthGuard;