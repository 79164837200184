import { Box, Grid, Typography } from "@mui/material"
import { ClearButton, GradientButton, GridItem, StyledAutocomplete, ThemedClearIcon } from "../../components/StyledComponents/CommonControls"
import { StyledTextField } from "../../components/StyledComponents/StyledTextField"
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import { Controller, DefaultValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { stateListArray } from "../../utils/constants/StateListConstant";
import { useEffect, useRef, useState } from "react";
import styles from './policyOwnerNameSearch.module.css';
import { CountyListData, getCountyByStateCode } from "../apn-search/getCounty/getCountySlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import LoadingProgressModel from "../../components/ui/circularProgress/LoadingProgress";
import { POLICY_OWNER_SEARCH_TAB } from "../../utils/constants/tabConstants";
import { stateParameter } from "../../entities/ApiModel/CountyRequest";
import { OwnerNameSearchForm } from "../../entities/Types/OwnerNameSearchForm";
import { errorMessages } from "./PolicyOwnerNameSearch";
import { resetPolicyOwnerSearchState } from "./policyOwnerNameSearchSlice";
import { getStateCountyList, StateCountyListData } from "../../common/Slice/countyListSlice";

interface Props {
    onSubmit: Function;
    onClear: Function,
    formFields: OwnerNameSearchForm,
    raiseError: Function
}

const PolicyOwnerNameSearchForm = ({ onSubmit, onClear, formFields, raiseError }: Props) => {

    const [countyList, setCounty] = useState<any>([]);
    const [isDisabled, setDisabled] = useState(true);
    const [isOwnerNameDisabled, setOwnerNameDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [stateValue, setStateValue] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [openCountyDropDown, setOpenCountyDropDown] = useState(false);
    const stateWiseCountyList = useAppSelector(StateCountyListData);
    const stateFieldClickRef = useRef(false);

    const isWildcardSearch = useRef(false);
    let countyRef: any;
    let stateRef: any;
    const stateFieldKeyPressed = useRef<string | null>(null);
    const ownerNameRef = useRef<HTMLInputElement | null>(null);

    const dispatch = useAppDispatch();
    const countyListState = useAppSelector(CountyListData);
    const rerenderRef = useRef(0);
    const defaultValues: DefaultValues<OwnerNameSearchForm> = {
        stateField: { id: "", label: "" },
        countyField: { county: "", fips: "" },
        ownerName: "",
    };

    useEffect(() => {
        if (!formFields || formFields.ownerName === "" || !formFields.countyField?.fips) {
            setDisabled(true);
            setOwnerNameDisabled(true);
        }
    }, []);

    const messageRequired = "Required"
    const ownerNameSearchValidationSchema = yup.object().shape({
        stateField: yup.object().shape({
            id: yup.string().required(messageRequired),
            label: yup.string().required(messageRequired),
        }),
        countyField: yup.object().shape({
            fips: yup.string().required(messageRequired),
            county: yup.string().required(messageRequired),
        }),
        ownerName: yup.string()
            // .matches(/^[a-zA-Z0-9.()/,:_+\-*&#; ]*$/, errorMessages.InvalidOwnerNameError)
            // .test('asterisk-count', errorMessages.DoubleAstrikError, value => {
            //     const asteriskCount = (value?.match(/\*/g) || []).length;
            //     return asteriskCount <= 1;
            // })
            .max(166, errorMessages.MaxCharactersError)
            .required(errorMessages.OwnerNameRequiredError),
    });

    const form = useForm<OwnerNameSearchForm>({
        defaultValues,
        shouldFocusError: false,
        resolver: yupResolver(ownerNameSearchValidationSchema)
    });
    const { register, handleSubmit, setValue, resetField, reset, watch, clearErrors, getValues, setFocus, setError, formState: { errors }, control } = form;

    async function handleSubmitClick(formData: OwnerNameSearchForm) {
        formData.ownerName = formData.ownerName.trim();
        if (!formData.ownerName) {
            setError("ownerName", { type: "required", message: errorMessages.OwnerNameRequiredError });
            return;
        }

        // if (getLengthOfAstrik(formData.APNField) === 1) {
        //     isWildcardSearch.current = true;
        //     formData = { ...formData, APNField: formData.APNField.split('*')[0] + "*" }
        // }
        // else if (getLengthOfAstrik(formData.APNField) > 1) {
        //     setError("APNField", { type: "InvalidAPN", message: "Invalid APN" })
        //     isWildcardSearch.current = false;
        // }
        // else
        //     isWildcardSearch.current = false;

        // formData = { ...formData, isWildCardSearch: isWildcardSearch.current }
        onSubmit(formData);
    }

    // useEffect(() => {
    //     if (countyListState.status === "loading")
    //         setLoading(true);
    //     else
    //         setLoading(false)

    //     if (countyListState && countyListState.county.match_code === "1"
    //         && (selectedState || formFields?.stateField?.id) && !countyListState.county.isExecuting) {
    //         let county = countyListState.county.countyList[POLICY_OWNER_SEARCH_TAB];
    //         setCounty(county);
    //         setDisabled(false);
    //         rerenderRef.current += 1;
    //         // setStateValue(prev => getValues("stateField.id"));
    //         setStateValue(getValues("stateField.id"));
    //     }
    // }, [countyListState]);

    useEffect(() => {
        if (stateValue && !isDisabled && stateFieldClickRef.current) {
            setOpenCountyDropDown(true);
            countyRef.focus();
            stateFieldClickRef.current = false;
        }
    }, [isDisabled, rerenderRef.current]);

    useEffect(() => {
        if (formFields
            && formFields?.ownerName
            && formFields?.countyField?.fips
            && formFields?.stateField?.id) {
            setValue("ownerName", formFields.ownerName);
            setValue("countyField", formFields.countyField);
            setValue("stateField", formFields.stateField);
            setOwnerNameDisabled(false);
        }
    }, [formFields]);

    useEffect(() => {
        raiseError(errors);
        if (!form.getValues()?.stateField?.id) {
            setFocus("stateField")
            return
            // stateRef.focus();
        }
        else if (!form.getValues()?.countyField?.fips) {
            setFocus("countyField")
            // countyRef.focus();
        }
        else if (!form.getValues()?.ownerName) {
            setFocus("ownerName")
            // countyRef.focus();
        }
    }, [errors.stateField, errors.countyField, errors.ownerName]);

    const setCountyList = (state: any) => {
        let countyList = stateWiseCountyList.stateCountyList[state.id];
        setCounty(countyList);
        setDisabled(false);
        rerenderRef.current += 1;
        setStateValue(prev => getValues("stateField.id"));
    }

    useEffect(() => {
        // if (stateWiseCountyList.status === "loading")
        //     setLoading(true);
        // else
        //     setLoading(false);

        if (stateWiseCountyList && stateWiseCountyList.match_code && stateWiseCountyList.match_code === "1"
            && !stateWiseCountyList.isExecuting
        ) {
            let state = getValues("stateField");
            if (state.id) {
                setCountyList(state);
            }
        }
    }, [stateWiseCountyList]);

    const getCountyList = (state: any) => {
        if (state && state.id) {
            if (stateWiseCountyList && stateWiseCountyList.stateCountyList &&
                stateWiseCountyList.stateCountyList[state.id]
            ) {
                // setCounty([]);
                resetField("countyField")
                resetField("ownerName")
                stateFieldClickRef.current = true;
                setCountyList(state);
            }
            else {
                dispatch(getStateCountyList())
            }
        }
    }


    // const getCountyList = (value: any) => {
    //     if (value && value.id) {
    //         setCounty([]);
    //         resetField("countyField")
    //         resetField("ownerName")
    //         const state = value.id.trim().toUpperCase();
    //         let param: stateParameter = {
    //             stateCode: state,
    //             pageName: POLICY_OWNER_SEARCH_TAB
    //         }
    //         setSelectedState(state);
    //         dispatch(getCountyByStateCode(param))
    //     }
    // }

    const handleInputChange = (event: any, newInputValue: any) => {
        setInputValue(newInputValue);
    };

    const handleCountyChange = () => {
        resetField('ownerName');
        setOwnerNameDisabled(false);
        if (ownerNameRef.current) {
            setTimeout(() => {
                ownerNameRef?.current?.focus();
            }, 0);
        }
    }

    const resetErrorOnFields = () => {
        clearErrors("ownerName");
        clearErrors("countyField");
        clearErrors("stateField");
    }

    const clearSearchResults = () => {
        reset(defaultValues);
        isWildcardSearch.current = false;
        resetErrorOnFields();
        setDisabled(true);
        setOwnerNameDisabled(true);
        dispatch(resetPolicyOwnerSearchState())
        onClear();
    }

    const ownerNameFieldWatch = watch('ownerName');
    const [ownerNameFieldFocused, setOwnerNameFieldFocused] = useState<boolean>(false);

    const handleFocus = (e: any) => {
        switch (e?.target?.id) {
            case "ownerName": {
                setOwnerNameFieldFocused(true);
                break;
            }
        }
    }
    const handleBlur = (e: any) => {
        switch (e?.target?.id) {
            case "ownerName": {
                setOwnerNameFieldFocused(false);
                break;
            }
        }
    }

    const handleStateFieldKeyDown = (event: React.KeyboardEvent) => {
        stateFieldKeyPressed.current = event.key; // Store the key
    };

    const handleStateFieldBlur = (e: any) => {
        if (stateFieldKeyPressed.current === "Tab") {
            if (!e.target.value) {
                return;
            }
            const matchedState = stateListArray.find((state) => state.label === e.target.value.toUpperCase());
            if (matchedState) {
                setValue("stateField", matchedState);
                clearErrors("stateField");
                getCountyList(matchedState);
            }
        }
        stateFieldKeyPressed.current = null; // Reset the ref
    }

    return (
        <Box>
            {isLoading &&
                <LoadingProgressModel />
            }
            <form onSubmit={handleSubmit(handleSubmitClick)}
                noValidate>
                <Grid container>
                    <Grid item xs={2} md={1.3} lg={1.3} xl={0.9}>
                        <GridItem sx={{ paddingLeft: "0px" }}>
                            <Controller
                                name="stateField"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <StyledAutocomplete
                                        {...field}
                                        {...register("stateField")}
                                        options={stateListArray}
                                        disableClearable
                                        getOptionLabel={(item: any) => (item.label ? item.label : "")}
                                        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                        noOptionsText={"No state"}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                inputRef={input => {
                                                    stateRef = input;
                                                }}
                                                error={!!fieldState.error}
                                                onBlur={handleStateFieldBlur}
                                                onKeyDown={handleStateFieldKeyDown}
                                                autoFocus={true}
                                                label="State"
                                                required
                                                variant="outlined"
                                                data-testid="StateField"
                                            />
                                        )}
                                        onChange={(_, data) => { field.onChange(data); getCountyList(data) }}
                                    />
                                )}
                                data-testid="StateAutocomplete"
                            />
                        </GridItem>
                    </Grid>
                    <Grid item xs={5} md={3.5} lg={3.3} xl={2.5}>
                        <GridItem>
                            <Controller
                                name="countyField"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <StyledAutocomplete
                                        {...field}
                                        disabled={isDisabled}
                                        open={openCountyDropDown}
                                        onOpen={() => setOpenCountyDropDown(true)}
                                        onClose={() => setOpenCountyDropDown(false)}
                                        // openOnFocus
                                        {...register("countyField")}
                                        options={countyList}
                                        disableClearable
                                        noOptionsText={"No such county"}
                                        getOptionLabel={(item: any) => (item.county ? item.county : "")}
                                        isOptionEqualToValue={(option: any, value: any) => option.fips === value.fips}
                                        inputValue={inputValue}
                                        onInputChange={handleInputChange}
                                        onChange={(_, data) => { field.onChange(data); handleCountyChange() }}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                inputRef={input => {
                                                    countyRef = input;
                                                }}
                                                error={!!fieldState.error}
                                                label="County"
                                                required
                                                variant="outlined"
                                                data-testid="CountyField"
                                            />
                                        )}
                                        data-testid="CountyAutocomplete"
                                    />
                                )}
                            />
                        </GridItem>
                    </Grid>
                    <Grid item xs={5} md={3.1} lg={3.9} xl={5.1}>
                        <GridItem>
                            <StyledTextField
                                {...register('ownerName')}
                                error={errors.ownerName && errors.ownerName.message !== "" ? true : false}
                                id="ownerName"
                                inputRef={ownerNameRef}
                                InputLabelProps={{
                                    shrink: !!ownerNameFieldWatch || ownerNameFieldFocused
                                }}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                disabled={isOwnerNameDisabled}
                                data-testid="ownerName"
                                fullWidth label="Owner Name" required variant="outlined" />
                        </GridItem>
                    </Grid>
                    <Grid item md={4.1} lg={3.5} xl={3.5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <GridItem sx={{ textAlign: 'unset' }}>
                            <GradientButton type="submit" size='large' className={styles.buttonPadding}
                                data-testid="SearchButton" variant="contained" startIcon={<SearchIcon sx={{ fontSize: 25 }} />}>
                                <Typography>Search</Typography>
                            </GradientButton>
                            <ClearButton type="reset" size="large" className={styles.buttonPadding} sx={{ marginLeft: '23px' }}
                                data-testid="ClearButton" onClick={clearSearchResults} variant="outlined" startIcon={<ThemedClearIcon sx={{ fontSize: 25 }} />}>
                                <Typography>Clear</Typography>
                            </ClearButton>
                        </GridItem>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default PolicyOwnerNameSearchForm