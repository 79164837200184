import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import MsalInstanceProvider from './configuration/MsalInstanceProvider';
import Unauthenticated from './features/authentication/Unauthenticated';
import Authenticated from './features/authentication/Authenticated';
import { useEffect } from 'react';
import { getEnvironmentTitle } from './utils/common/commonUtils';
import { ThemeProvider } from '@emotion/react';
import { Box, Container, CssBaseline } from '@mui/material';
import theme from './theme';
import AppLayout from './AppLayout';
import LoginLayout from './LoginLayout';

function App() {
  useEffect(() => {
    document.title = getEnvironmentTitle();
  }, [])
  return (
    <MsalInstanceProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline /> {/* Ensures consistent baseline styling */}
        <>
          <AuthenticatedTemplate>
            {/* <AppLayout> */}
              <Authenticated />
            {/* </AppLayout> */}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            {/* <LoginLayout> */}
              <Unauthenticated />
            {/* </LoginLayout> */}
          </UnauthenticatedTemplate>
        </>
      </ThemeProvider>
    </MsalInstanceProvider>
  );
}

export default App;
