import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Navbar from '../../layout/navbar/navbar';
import styles from './login.module.css';
import Footer from '../../layout/footer/Footer';
import { MailToBody, MailToCustomerCare, MailToSubject } from '../../utils/constants/constants';
import LoginImageSection from './BackgroundImage';

export type GraphData = {
    displayName: string,
    jobTitle: string,
    mail: string,
    businessPhones: string[],
    officeLocation: string
};

export default function InvalidAccess() {
    const triggerMailTo = (event: any) => {
        event.preventDefault();
        const mailTo = "mailto:" + MailToCustomerCare + "?subject=" + MailToSubject + "&body=" + MailToBody;
        window.location.href = mailTo;
    }
    return (
        <>
            {/* <Grid container component="main" sx={{ minHeight: '80vh' }}> */}
            <Grid item xs={12} sm={8} md={6} sx={{ backgroundColor: '#e5e5e5' }}>
                <Box
                    sx={{
                        my: 27,
                        mx: 4,
                        textAlign: 'center'
                    }}
                    color={'black'}>
                    <Typography component="h1" variant="h3" sx={{ fontWeight: 600 }}>Invalid Account</Typography>
                    <Box sx={{
                        my: 5,
                        mx: 4
                    }}
                        className={styles.displayBox}>
                        <Typography component="h1" variant="h6" color={'#E00000'}>Your account does not have access. Please request access</Typography>
                        <Typography component="h1" variant="h6" color={'#E00000'}>below or contact SPECTR Support</Typography>
                        <Typography component="h1" variant="h6" color={'#E00000'}>at SPECTR_support@stewart.com</Typography>
                    </Box>
                    <Link className={styles.requestButton} onClick={triggerMailTo}>Request Access</Link>
                </Box>
            </Grid>
            <LoginImageSection />
            {/* </Grid> */}
        </>
    );
}