import React from "react";
import { Box, Container } from "@mui/material";
import Footer from "./layout/footer/Footer";
import Navbar from "./layout/navbar/navbar";

const AppLayout = ({ children }: { children: React.ReactNode }) => {

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                }}>
                <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                    <Container maxWidth={false} sx={{ maxWidth: "1920px" }}>
                        <Navbar />
                        <Box sx={{ flexGrow: 1, margin: "0 auto", width: "100%", marginTop: "24px" }}>
                            {children}
                        </Box>
                    </Container>
                </Box>
                <Container maxWidth={false} sx={{ maxWidth: "1920px", marginTop: '20px' }}>
                    <Box >
                        <Footer />
                    </Box>
                </Container>
            </Box>

        </>
    );
};

export default AppLayout;
