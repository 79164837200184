import React, { useEffect, useRef, useState } from 'react'
import { SectionCard, SectionDataBox } from '../../../components/StyledComponents/ParcelDetailStyles'
import styles from './policySearchResult.module.css';
import HeaderResult from './HeaderResult';
import CollapseHeader from './CollapseHeader';
import APNResult from './APNResult';
import DirectStarterHeader from '../../parcelDetails/Headers/DirectStarterHeader';
import ReferenceStarterHeader from '../../parcelDetails/Headers/ReferenceStarterHeader';
import { keyEntriesPerPage, keyPageNo, search_status_subdivision } from '../../../utils/constants/constants';
import { Box } from '@mui/material';
import StewartTableFooterPaginator from '../../../components/ui/spectr-table/StewartTableFooterPaginator';
import { getSessionStorage, setSessionStorage } from '../../../utils/common/commonUtils';

type Props = {
    propertyList: any;
    tabName: string;
    subTabName?: string;
    isDirectStarter?: any;
    isReferenceStarter?: any;
    searchStatus?: any
    fetchNextData?: Function
    changeRowsPerPage?: Function
    totalRowCount?: number
    isPaginationRequired?: Boolean
    isPaginationLogicRequired?: Boolean
    loggingEventName: string
};

interface StateType {
    [key: number]: boolean;
}

const PolicySearchResult = ({ propertyList, tabName, isDirectStarter, isReferenceStarter, loggingEventName,
    searchStatus, totalRowCount, fetchNextData, changeRowsPerPage, isPaginationRequired = true, isPaginationLogicRequired = true, subTabName = "" }: Props) => {
    const [propertyDetailsOpen, setPropertyDetails] = React.useState(false);
    //const [exceptionOpen, setExceptionOpen] = React.useState(false);
    const [policyDetailsOpenState, setPolicyDetailsOpenState] = useState<StateType | null>(null);
    const [APNDetailsOpenState, setAPNDetailsOpenState] = useState<StateType | null>(null);
    // Function to update state based on a key
    const toggleBoolean = (key: number) => {
        setPolicyDetailsOpenState(prevState => {
            if (prevState && prevState.hasOwnProperty(key)) {
                return {
                    ...prevState,
                    [key]: !prevState[key]
                };
            } else {
                return {
                    ...prevState,
                    [key]: true
                };
            }
        });
        console.log(policyDetailsOpenState);
    };

    const toggleAPNDetailOpen = (key: number) => {
        setAPNDetailsOpenState(prevState => {
            if (prevState && prevState.hasOwnProperty(key)) {
                return {
                    ...prevState,
                    [key]: !prevState[key]
                };
            } else {
                return {
                    ...prevState,
                    [key]: true
                };
            }
        });
        console.log(APNDetailsOpenState);
    };

    const handlePropertyDetailsClick = (row_number: number) => {
        let definedRownumber = parseInt(page + "" + row_number);
        toggleBoolean(definedRownumber);
        setPropertyDetails(!propertyDetailsOpen)
    }

    const handleAPNDetailsClick = (row_number: number) => {
        let definedRownumber = parseInt(page + "" + row_number);
        toggleAPNDetailOpen(definedRownumber);
        // setPropertyDetails(!propertyDetailsOpen)
    }

    // const handleExceptionClick = () => {
    //     setExceptionOpen(!exceptionOpen)
    // }

    const defaultRowsPerPage = 10;
    const defaultPageNo = 1;
    const isFirstRender = useRef(true);
    let navTabName = subTabName ? subTabName : tabName;

    const [page, setPage] = React.useState<number>(parseInt(getSessionStorage(keyPageNo + navTabName) ?? defaultPageNo.toString()));

    const [rowsPerPage, setRowsPerPage] = React.useState<number>(parseInt(getSessionStorage(keyEntriesPerPage + navTabName) ?? defaultRowsPerPage.toString()));

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 0);
    };

    const handleChangePage = (newPage: number) => {
        let navTabName = subTabName ? subTabName : tabName;
        setSessionStorage(keyPageNo + navTabName, newPage);
        setPage(newPage);
        scrollToTop();
        //fetchNextData((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
    };

    const handleChangeRowsPerPage = (count: any) => {
        scrollToTop();
        setRowsPerPage(count);
        let navTabName = subTabName ? subTabName : tabName;
        sessionStorage.removeItem(keyPageNo + navTabName);
        setSessionStorage(keyEntriesPerPage + navTabName, count);
        setPage(1);
        if (changeRowsPerPage) {
            changeRowsPerPage();
            if (fetchNextData) {
                fetchNextData(defaultPageNo, (defaultPageNo - 1) * count, (defaultPageNo - 1) * count + count);
            }
        }
    };

    useEffect(() => {
        if (!isFirstRender.current && !propertyList[page]) {
            // console.log("page", page);
            // console.log("Rows Per Page", (page - 1) * rowsPerPage)
            // console.log("fetchNext", (page - 1) * rowsPerPage + rowsPerPage)
            if (fetchNextData)
                fetchNextData(page, (page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
        }
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
        //console.log("Page Re-rendered");
        // if (fetchNextData)
        //     fetchNextData((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
        // let data = propertyList
        //     .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
        // if (data.length === 0) {
        //     if (fetchNextData)
        //         // fetchNextData((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
        //     // alert("No Data");
        //     return;
        // }
    }, [page]);

    return (
        <>
            <Box sx={{ position: 'relative', margin: '0px 0px 0px 0' }}>
                {
                    //.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                    (isPaginationLogicRequired ? propertyList[page] : propertyList)?.map((property: any, index: any) => (
                        <>
                            <SectionCard sx={{ marginTop: "15px" }} className={styles.borderSection}>
                                <SectionDataBox>
                                    <HeaderResult
                                        //key={page + "" + property?.policy_info?.row_number}
                                        isStarter={isDirectStarter || isReferenceStarter}
                                        isPropertyDetailsOpen={(policyDetailsOpenState ? policyDetailsOpenState[parseInt(page + "" + property?.policy_info?.row_number)] : false)}
                                        handlePropertyDetailsClick={handlePropertyDetailsClick}
                                        policyInfo={property?.policy_info}
                                        tabName={tabName}
                                        isDirectStarter={isDirectStarter}
                                        isReferenceStarter={isReferenceStarter} />
                                    <CollapseHeader
                                        key={page + "" + property?.policy_info?.row_number}
                                        isStarter={isDirectStarter || isReferenceStarter}
                                        isPropertyDetailsOpen={(policyDetailsOpenState ? policyDetailsOpenState[parseInt(page + "" + property?.policy_info?.row_number)] : false)}
                                        policyInfo={property?.policy_info}
                                        exceptionInfo={property?.exception_info} />
                                    {property?.apn_info?.map((apnDetail: any, index: any) => (
                                        <APNResult
                                            key={parseInt(page + "" + property?.policy_info?.row_number)}
                                            index={index}
                                            isStarter={isDirectStarter || isReferenceStarter}
                                            isDirect={isDirectStarter}
                                            isReference={isReferenceStarter}
                                            policyInfo={property?.policy_info}
                                            subTabName={subTabName}
                                            handleAPNDetailsClick={handleAPNDetailsClick}
                                            isAPNDetailsOpen={(APNDetailsOpenState ? APNDetailsOpenState[parseInt(page + "" + property?.policy_info?.row_number + "" + index)] : false)}
                                            apnDetail={apnDetail}
                                            tabName={tabName}
                                            loggingEventName={loggingEventName} />
                                    ))}
                                </SectionDataBox>
                            </SectionCard >
                        </>
                    ))
                }
                {isPaginationRequired && Object.keys(propertyList).length > 0 &&
                    propertyList[page]?.length > 0 &&
                    <Box sx={{ paddingTop: '24px', top: '100%', width: '100%', left: '0' }}>
                        <Box>
                            <StewartTableFooterPaginator
                                totalRows={totalRowCount ?? 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                isBackToTopRequired={true}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage} />
                        </Box>
                    </Box>
                }
            </Box>
        </>
    )
}

export default PolicySearchResult