import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
// import ReactHtmlParser from 'react-html-parser';
import styles from './exception.module.css';
import parse from 'html-react-parser';

interface Props {
    exceptionText: string,
    sequenceNumber: string,
    policyNumber: string
}
const PolicyExceptionText = ({ policyNumber, exceptionText, sequenceNumber }: Props) => {
    return (
        <>
            <ul>
                <li>
                    <Box className={styles.exceptionContainer}>
                        <Typography className={`${styles.ExceptionStyle} ${styles.ExceptionWidth}`}
                            sx={{ display: 'inline-block', verticalAlign: "top" }}
                            data-testid={policyNumber + "_ExceptionSequenceNumber"} lineHeight={"28px"}>{sequenceNumber}.</Typography>

                        <Typography className={styles.ExceptionStyle}
                            sx={{ display: 'inline-block', width: "96%", verticalAlign: "top" }}
                            data-testid={policyNumber + "_ExceptionText"} lineHeight={"28px"}>{parse(exceptionText)}</Typography>
                    </Box>
                </li>
            </ul>
        </>
    );
};

export default PolicyExceptionText;
