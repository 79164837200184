import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { ClearButton, GradientButton, GridItem, StyledAutocomplete, ThemedClearIcon } from '../../../components/StyledComponents/CommonControls'
import { StyledTextField } from '../../../components/StyledComponents/StyledTextField'
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import styles from '../policySearch.module.css';
import { PolicySearchForm } from '../../../entities/Types';
import { Controller, DefaultValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { errorTypes } from '../PolicySearch';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { resetPolicySearchState } from '../policySearchSlice';
import { stateListArray } from '../../../utils/constants/StateListConstant';
import { getStateCountyList, StateCountyListData } from '../../../common/Slice/countyListSlice';

interface Props {
    raiseError: Function
    onSubmit: Function;
    onClear: Function,
    formFields: PolicySearchForm
}

const SearchForm = ({ raiseError, onSubmit, onClear, formFields }: Props) => {
    const dispatch = useAppDispatch();
    const [countyList, setCounty] = useState<any>([]);
    const [isDisabled, setDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [stateValue, setStateValue] = useState<string | undefined>("");
    const [openCountyDropDown, setOpenCountyDropDown] = useState(false);

    let countyRef: any;
    let stateRef: any;
    const rerenderRef = useRef(0);
    const stateFieldClickRef = useRef(false);
    const stateFieldKeyPressed = useRef<string | null>(null);
    const stateWiseCountyList = useAppSelector(StateCountyListData);
    const policyNumberRef = useRef<HTMLInputElement | null>(null);

    const defaultValues: DefaultValues<PolicySearchForm> = {
        stateField: { id: "", label: "" },
        countyField: { county: "", fips: "" },
        policyNumber: "",
    };

    const PolicySearchValidationSchema = yup.object().shape({
        policyNumber: yup.string()
            .matches(/^[a-zA-Z0-9 \-\/.,#:&()_\[\]\\+$*;?='@`]*$/, errorTypes.MatchError)
            .max(100, errorTypes.MaxError)
            .required(errorTypes.RequiredError)
    });
    const form = useForm<PolicySearchForm>({
        defaultValues,
        resolver: yupResolver(PolicySearchValidationSchema)
    });
    const { register, handleSubmit, getValues, setValue, reset, watch, clearErrors, resetField, setError, control, formState: { errors } } = form;
    const policyNumberWatch = watch('policyNumber');
    const [policyNumberFocused, setPolicyNumberFocused] = useState<boolean>(false);
    const handleSubmitClick = (formData: PolicySearchForm) => {
        formData.policyNumber = formData.policyNumber.trim();
        if (!formData.policyNumber)
            setError("policyNumber", { type: errorTypes.RequiredError, message: errorTypes.RequiredError });
        onSubmit(formData);
    }

    const clearSearchResults = () => {
        reset(defaultValues);
        setDisabled(true);
        clearErrors("policyNumber");
        dispatch(resetPolicySearchState())
        onClear();
    }

    useEffect(() => {
        if (formFields
            && formFields.policyNumber) {
            setValue("policyNumber", formFields.policyNumber);
            setValue("stateField", formFields.stateField);
            setValue("countyField", formFields.countyField);
        }
    }, [formFields]);

    useEffect(() => {
        console.log(errors);
        raiseError(errors.policyNumber?.message);
    }, [errors.policyNumber?.type]);

    const handleFocus = (e: any) => {
        switch (e?.target?.id) {
            case "policyNumber": {
                setPolicyNumberFocused(true);
                break;
            }
        }
    }
    const handleBlur = (e: any) => {
        switch (e?.target?.id) {
            case "policyNumber": {
                setPolicyNumberFocused(false);
                break;
            }
        }
    }

    const setCountyList = (state: any) => {
        let countyList = stateWiseCountyList.stateCountyList[state.id];
        setCounty(countyList);
        setDisabled(false);
        rerenderRef.current += 1;
        setStateValue(prev => getValues("stateField.id"));
    }

    useEffect(() => {
        if (stateValue && !isDisabled && stateFieldClickRef.current) {
            setOpenCountyDropDown(true);
            countyRef.focus();
            stateFieldClickRef.current = false;
        }
    }, [isDisabled, rerenderRef.current]);

    useEffect(() => {
        // if (stateWiseCountyList.status === "loading")
        //     setLoading(true);
        // else
        //     setLoading(false);

        if (stateWiseCountyList && stateWiseCountyList.match_code && stateWiseCountyList.match_code === "1"
            && !stateWiseCountyList.isExecuting
        ) {
            let state = getValues("stateField");
            if (state?.id) {
                setCountyList(state);
            }
        }
    }, [stateWiseCountyList]);

    const getCountyList = (state: any) => {
        if (state && state.id) {
            if (stateWiseCountyList && stateWiseCountyList.stateCountyList &&
                stateWiseCountyList.stateCountyList[state.id]
            ) {
                // setCounty([]);
                resetField("countyField")
                // resetField("APNField")
                // isWildcardSearch.current = false;
                stateFieldClickRef.current = true;
                setCountyList(state);
            }
            else {
                dispatch(getStateCountyList())
            }
        }
    }

    const handleStateFieldBlur = (e: any) => {
        if (stateFieldKeyPressed.current === "Tab") {
            if (!e.target.value) {
                return;
            }
            const matchedState = stateListArray.find((state) => state.label === e.target.value.toUpperCase());
            if (matchedState) {
                setValue("stateField", matchedState);
                clearErrors("stateField");
                getCountyList(matchedState);
            }
        }
        stateFieldKeyPressed.current = null; // Reset the ref
    }

    const handleStateFieldKeyDown = (event: React.KeyboardEvent) => {
        stateFieldKeyPressed.current = event.key; // Store the key
    };

    const handleInputChange = (event: any, newInputValue: any) => {
        setInputValue(newInputValue);
    };

    const handleCountyChange = () => {
        // resetField('APNField');
        // isWildcardSearch.current = false;
        // setAPNDisabled(false);
        if (policyNumberRef.current) {
            setTimeout(() => {
                policyNumberRef?.current?.focus();
            }, 0);
        }
    }

    return (
        <Box>
            <form noValidate onSubmit={handleSubmit(handleSubmitClick)}>
                <Grid container>
                    <Grid item xs={2} md={1.3} lg={1.3} xl={0.9}>
                        <GridItem sx={{ paddingLeft: "0px" }}>
                            <Controller
                                name="stateField"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <StyledAutocomplete
                                        {...field}
                                        {...register("stateField")}
                                        options={stateListArray}
                                        disableClearable
                                        getOptionLabel={(item: any) => (item.label ? item.label : "")}
                                        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                        noOptionsText={"No state"}
                                        renderInput={(params: any) => (
                                            <StyledTextField
                                                {...params}
                                                inputRef={input => {
                                                    stateRef = input;
                                                }}
                                                error={!!fieldState.error}
                                                onBlur={handleStateFieldBlur}
                                                onKeyDown={handleStateFieldKeyDown}
                                                autoFocus={true}
                                                label="State"
                                                variant="outlined"
                                                data-testid="StateField"
                                            />
                                        )}
                                        onChange={(_, data) => { field.onChange(data); getCountyList(data) }}
                                    />
                                )}
                                data-testid="StateAutocomplete"
                            />
                        </GridItem>
                    </Grid>
                    <Grid item xs={5} md={3.5} lg={3.3} xl={2.5}>
                        <GridItem>
                            <Controller
                                name="countyField"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <StyledAutocomplete
                                        {...field}
                                        disabled={isDisabled}
                                        open={openCountyDropDown}
                                        onOpen={() => setOpenCountyDropDown(true)}
                                        onClose={() => setOpenCountyDropDown(false)}
                                        // openOnFocus
                                        {...register("countyField")}
                                        options={countyList}
                                        disableClearable
                                        noOptionsText={"No such county"}
                                        getOptionLabel={(item: any) => (item.county ? item.county : "")}
                                        isOptionEqualToValue={(option: any, value: any) => option.fips === value.fips}
                                        inputValue={inputValue}
                                        onInputChange={handleInputChange}
                                        onChange={(_, data) => { field.onChange(data); handleCountyChange() }}
                                        renderInput={(params) => (
                                            <StyledTextField
                                                {...params}
                                                inputRef={input => {
                                                    countyRef = input;
                                                }}
                                                error={!!fieldState.error}
                                                label="County"
                                                variant="outlined"
                                                data-testid="CountyField"
                                            />
                                        )}
                                        data-testid="CountyAutocomplete"
                                    />
                                )}
                            />
                        </GridItem>
                    </Grid>
                    <Grid item xs={5} md={4.7} lg={4} xl={3.5}>
                        <GridItem sx={{ paddingLeft: "0px" }}>
                            <StyledTextField
                                {...register("policyNumber")}
                                InputLabelProps={{
                                    shrink: !!policyNumberWatch || policyNumberFocused
                                }}
                                inputRef={policyNumberRef}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                autoFocus
                                required
                                error={errors.policyNumber && errors.policyNumber.message !== "" ? true : false}
                                id="policyNumber" fullWidth label="Policy Number / File Number / Order Number" data-testid="PolicyNumber"
                            />
                        </GridItem>
                    </Grid>
                    <Grid item md={4} lg={3} xl={3.5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }} >
                        <GridItem sx={{ textAlign: 'left' }}>
                            <GradientButton type="submit" size="large" data-testid="SearchButton" className={styles.buttonPadding} variant="contained" startIcon={<SearchIcon sx={{ fontSize: 25 }} />}>
                                <Typography>Search</Typography>
                            </GradientButton>
                            <ClearButton type="reset" size="large" data-testid="ClearButton" onClick={clearSearchResults} className={styles.buttonPadding} sx={{ marginLeft: '23px' }} variant="outlined" startIcon={<ThemedClearIcon sx={{ fontSize: 25 }} />}>
                                <Typography>Clear</Typography>
                            </ClearButton>
                        </GridItem>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default SearchForm