import { combineReducers } from "@reduxjs/toolkit"
import addressReducer from "../features/addressSearch/addressSearchSlice"
import policyAddressReducer from "../features/policyAddressSearch/policyAddressSearchSlice"
import parcelDetails from "../features/parcelDetails/parcelDetailsSlice"
import policyDoc from "../common/Slice/policyDocumentSlice"
import CountyListData from "../features/apn-search/getCounty/getCountySlice"
import apnList from "../features/apn-search/apnSearchSlice"
import tabIdentifier from "../components/ui/propertyTable/tabIdentifierSlice"
import PolicySearchData from "../features/policySearch/policySearchSlice"
import FeatureFlagData from "../common/Slice/featureFlagSlice"
import LegalSearchData from "../features/legalSearch/legalSearchForm/LegalSearchSlice"
import AssessmentSearchData from "../features/assessmentSearch/AssessmentSearchSlice"
import policyAPNList from "../features/policyAPNSearch/policyAPNSearchSlice"
import AssessmentOwnerSearchData from "../features/ownerNameSearch/AssessmentOwnerSearchSlice"
import PolicyOwnerSearchData from "../features/PolicyOwnerNameSearch/policyOwnerNameSearchSlice"
import StateCountyListData from "../common/Slice/countyListSlice"

const rootReducer = combineReducers({
    address: addressReducer,
    policyAddress: policyAddressReducer,
    parcelDetails: parcelDetails,
    policyDocument: policyDoc,
    CountyList: CountyListData,
    PolicySearchList: PolicySearchData,
    LegalSearchList: LegalSearchData,
    AssessmentSearchList: AssessmentSearchData,
    AssessmentOwnerSearchList: AssessmentOwnerSearchData,
    PolicyOwnerSearchList: PolicyOwnerSearchData,
    FeatureFlag: FeatureFlagData,
    StateCountyList: StateCountyListData,
    APN: apnList,
    PolicyAPN: policyAPNList,
    Tab: tabIdentifier,
})

export default rootReducer