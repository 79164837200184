import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchStateCountyList } from '../Api/countyListAPI';

interface County {
    county: string;
    fips: string;
}

interface CountyList {
    [stateCode: string]: County[];
}

export interface CountyListState {
    match_code: string,
    stateCountyList: CountyList,
    total_count: number
    isExecuting: boolean,
    status: 'idle' | 'loading' | 'failed' | 'reset';
}

export const resetStateCountyList = createAction('REVERT_COUNTY_LIST_STATE');

const initialState: CountyListState = {
    match_code: "",
    stateCountyList: {},
    total_count: 0,
    isExecuting: false,
    status: 'idle',
};

export const getStateCountyList = createAsyncThunk(
    'County/getStateCountyList',
    async () => {
        const response = await fetchStateCountyList();
        return response;
    }
);

export const getStateCountyListSlice = createSlice({
    name: 'StateCountyList',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStateCountyList.pending, (state) => {
                state.status = 'loading';
                state.match_code = "";
                state.stateCountyList = {};
                state.total_count = 0;
                state.isExecuting = true;
            })
            .addCase(getStateCountyList.fulfilled, (state, action) => {
                state.status = 'idle';
                if (!action.payload)
                    return;
                state.match_code = action.payload.data.match_code;
                state.stateCountyList = action.payload.data.state_county_list;
                state.total_count = action.payload.data.total_count;
                state.isExecuting = false;
            })
            .addCase(getStateCountyList.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(resetStateCountyList, () => initialState);
    },
});

export const StateCountyListData = (state: RootState) => state.rootReducer.StateCountyList;
export default getStateCountyListSlice.reducer;
