import { Navigate, type RouteObject } from 'react-router';
import ParcelDetails from './features/parcelDetails/ParcelDetails';
import PageNotFound from './features/notFoundpage/NotFound';
import SearchLayout from './layout/searchLayout/searchLayout';
import Login from './features/authentication/Login';
import AccessDenied from './features/accessDenied/AccessDenied';
import NoContent from './features/noContent/NoContent';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import AuthGuard from './AuthGuard';
import AppLayout from './AppLayout';

// const LoginRedirectHandler = () => {
//   const isAuthenticated = useIsAuthenticated();
//   // const isTokenExpired = useIsTokenExpired();

//   let status = true;
//   const { instance } = useMsal();
//   const activeAccount = instance.getActiveAccount();
//   if (!activeAccount || !activeAccount.idTokenClaims) {
//     status = true;
//   }
//   // const { exp } = activeAccount?.idTokenClaims;
//   const claims = activeAccount?.idTokenClaims as { exp?: number };
//   if (!claims.exp) {
//     status = true;
//   }
//   const expiryTime = 1741257571 * 1000; // Convert to milliseconds
//   const currentTime = Date.now();
//   status = currentTime >= expiryTime;
//   console.log(status);

//   if (!isAuthenticated || status) {
//     window.location.href = "/login"
//     return null;
//   }
//   else {
//     return <Navigate to="/address-search" replace />;
//   }
// };

const routesConfig: RouteObject[] = [
  { path: '/login', element: <Login /> },
  {
    path: '/',
    element: <AuthGuard />,
    children: [
      { path: '', element: <AppLayout><SearchLayout /></AppLayout> }, // Rendered at "/"
      { path: 'address-search', element: <AppLayout><SearchLayout /></AppLayout> },
      { path: 'parcel-detail/:propertyId', element: <AppLayout><ParcelDetails /></AppLayout> },
      { path: 'access-denied', element: <AppLayout><AccessDenied /></AppLayout> },
      { path: 'no-content', element: <AppLayout><NoContent /></AppLayout> },
    ],
  },
  { path: '*', element: <PageNotFound /> },
];
export default routesConfig;
