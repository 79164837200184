import * as React from 'react';
import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAccount, useMsal } from '@azure/msal-react';
import { EndSessionRequest } from '@azure/msal-browser';
import config from "../../appsettings.json";

function UserAccount() {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const { instance } = useMsal();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");

    useEffect(() => {
        if (account && account.name) {
            setName(account.name)
        } else {
            setName("Unknown");
        }
    }, [account]);

    const handleLogout = async () => {
        const logoutRequest: EndSessionRequest = {
            account: account,
            postLogoutRedirectUri: "/"
        }
        await instance.logoutRedirect(logoutRequest);
        handleCloseUserMenu();
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleDemoVideoClick = () => {
        handleCloseUserMenu();
        window.open(config.GeneralSettings.DemoVideoLink, "_blank");
    };

    const handleUserGuideClick = () => {
        handleCloseUserMenu();
        window.open("/" + config.GeneralSettings.UserGuideFileName, "_blank");
    };

    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title="Open settings">
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                pl: 1,
                                display: { xs: 'none', md: 'flex' },
                                letterSpacing: '.1rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                fontSize: '15px',
                            }}>
                            {name ? name : 'Unknown'}
                        </Typography>
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}>
                    {/* <MenuItem key="Profile" onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">Profile</Typography>
                    </MenuItem>
                    <MenuItem key="Account" onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">Account</Typography>
                    </MenuItem> */}
                    <MenuItem key="UserGuide" onClick={handleUserGuideClick}>
                        <Typography textAlign="center">User Guide</Typography>
                    </MenuItem>
                    <MenuItem key="DemoVideo" onClick={handleDemoVideoClick}>
                        <Typography textAlign="center">Demo Video</Typography>
                    </MenuItem>
                    <MenuItem key="Logout" onClick={handleLogout}>
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                </Menu>
            </Box>
        </>
    )
}

export default UserAccount