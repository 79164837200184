import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getStateCountyList, StateCountyListData } from "../common/Slice/countyListSlice";

function useStateContyList() {
    const countyList = useAppSelector(StateCountyListData);
    const dispatch = useAppDispatch();
    useMemo(async () => {
        // if (featureflag && featureflag.featureflag.data.match_code === "2") {
        //     return;
        // }
        if (!countyList || !countyList.stateCountyList || Object.keys(countyList.stateCountyList).length === 0) {
            try {
                dispatch(getStateCountyList())
                return true;
            } catch (error) { }
        }
    }, []);
}


export default useStateContyList; 