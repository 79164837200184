import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Link, Typography } from '@mui/material';
import config from "../../appsettings.json";

interface Props {
  isVersionRequired?: boolean
}

function Footer({ isVersionRequired = false }: Props) {
  const Div = styled('div')(({ theme }) => ({
    fontSize: 14,
    marginTop: 6
  }));

  const FooterLink = styled(Link)`
    padding-right: 25px;
    color: ${(props) => props.theme.palette.primary.main};
  `;

  // const FooterLink = styled(Link)(({ theme }) => ({
  //   paddingRight: '25px',
  //   color: '#0075aa'
  // }));

  return (
    <>
      <FooterLink href="https://www.stewart.com/en/privacy.html" target="_blank">Privacy Policy</FooterLink>
      <FooterLink href="https://www.stewart.com/en/terms-of-use.html" target="_blank">Terms of Use</FooterLink>
      <FooterLink href="https://www.stewart.com/en/protecting-customer-information.html" target="_blank">Protecting Customer Information</FooterLink>
      <Div sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "14px", marginTop: "6px" }}>&#169; {new Date().getFullYear()} Stewart Title Guaranty Company. All Rights Reserved. Trademarks are the property of their respective owners.</Typography>
        {
          isVersionRequired &&
          <Typography sx={{ fontSize: "14px", fontStyle: "italic", color: "#7F7F7F" }}>Version {config.GeneralSettings.AppVersion}</Typography>
        }
      </Div>
    </>
  );
}
export default Footer;