import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
//Tabs
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import AddressSearch from '../../features/addressSearch/AddressSearch';
import styles from './searchLayout.module.css';
import { Container } from '@mui/material';
import Navbar from '../navbar/navbar';
import Footer from '../footer/Footer';
import APNsearch from '../../features/apn-search/APNsearch';
import {
    ADDRESS_TAB,
    ADDRESS_TAB_HEADER,
    APN_TAB,
    APN_TAB_HEADER,
    LEGAL_SEARCH_TAB,
    LEGAL_SEARCH_TAB_HEADER,
    OWNER_SEARCH_TAB,
    OWNER_SEARCH_TAB_HEADER,
    POLICY_SEARCH_TAB,
    POLICY_SEARCH_TAB_HEADER
} from '../../utils/constants/tabConstants';
import { useAppSelector } from '../../app/hooks';
import { tabIdentifier } from '../../components/ui/propertyTable/tabIdentifierSlice';
import PolicySearch from '../../features/policySearch/PolicySearch';
import { FeatureFlagData } from '../../common/Slice/featureFlagSlice';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import OwnerNameSearch from '../../features/ownerNameSearch/OwnerNameSearch';
import AssessmentSearch from '../../features/assessmentSearch/AssessmentSearch';
import { CustomStyledTab, StyledTabs } from '../../components/StyledComponents/CommonControls';
import useStateContyList from '../../hooks/useCountyList';

function SearchLayout() {
    useFeatureFlag("");
    useStateContyList();
    const tabState = useAppSelector(tabIdentifier);
    const featureFlag = useAppSelector(FeatureFlagData);
    const [value, setValue] = React.useState<string>(tabState.tabName || ADDRESS_TAB);
    // const [value, setValue] = React.useState<string>(ADDRESS_TAB);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    // const [isPolicySearchVisible, setPolicySearchVisible] = React.useState<boolean>(false);
    // const [isLegalSearchVisible, setLegalSearchVisible] = React.useState<boolean>(false);

    useEffect(() => {
        if (featureFlag && featureFlag.featureflag.data.match_code === "2") {
            let flag = featureFlag.featureflag.data.featureflag;
            // const legalSearchObject: any = flag.find(obj => "LegalSearch" in obj);
            // const policySearchObject: any = flag.find(obj => "PolicySearch" in obj);
            // if (legalSearchObject && Object.keys(legalSearchObject).length > 0) {
            //     setLegalSearchVisible(legalSearchObject.LegalSearch.enabled ?? false);
            // }
            // if (policySearchObject && Object.keys(policySearchObject).length > 0) {
            //     setPolicySearchVisible(policySearchObject.PolicySearch.enabled ?? false);
            // }
        }
    }, [featureFlag]);

    // useEffect(() => {
    //     if (tabState.tabName)
    //         setValue(tabState.tabName)
    //     else
    //         setValue(ADDRESS_TAB)

    //     // switch (tabState.tabName) {
    //     //     case "addressTab": {
    //     //         setHeaderText(ADDRESS_TAB_HEADER);
    //     //         break;
    //     //     }
    //     //     case "apnTab": {
    //     //         setHeaderText(APN_TAB_HEADER);
    //     //         break;
    //     //     }
    //     //     default: {
    //     //         setHeaderText(ADDRESS_TAB_HEADER);
    //     //         break;
    //     //     }
    //     // }
    // }, []);

    useEffect(() => {
        setValue(tabState.tabName || ADDRESS_TAB);
    }, [tabState]);

    // if (!tabState.tabName)

    //     return null; // or a loading spinner

    return (
        <div>
            <Box sx={{ width: '100%', minHeight: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box>
                        <StyledTabs
                            value={value}
                            onChange={handleChange}
                            scrollButtons={false}
                            aria-label="Address Search Tabs">
                            <CustomStyledTab value={ADDRESS_TAB} label={ADDRESS_TAB_HEADER} />
                            <CustomStyledTab value={APN_TAB} label={APN_TAB_HEADER} />
                            <CustomStyledTab value={POLICY_SEARCH_TAB} label={POLICY_SEARCH_TAB_HEADER} />
                            <CustomStyledTab value={LEGAL_SEARCH_TAB} label={LEGAL_SEARCH_TAB_HEADER} />
                            <CustomStyledTab sx={{ borderTopRightRadius: "5px" }} value={OWNER_SEARCH_TAB} label={OWNER_SEARCH_TAB_HEADER} />
                        </StyledTabs>
                    </Box>
                    <Box className={styles.boxLayout}>
                        <AddressSearch />
                        <APNsearch />
                        <PolicySearch />
                        <AssessmentSearch />
                        <OwnerNameSearch />
                    </Box>
                </TabContext>
            </Box>
        </div>
    )
}

export default SearchLayout;