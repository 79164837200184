import { Box, Grid, Typography } from "@mui/material"
import { ClearButton, GradientButton, GridItem, StyledAutocomplete, ThemedClearIcon } from "../../../components/StyledComponents/CommonControls"
import { StyledTextField } from "../../../components/StyledComponents/StyledTextField"
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import { Controller, DefaultValues, useForm } from "react-hook-form";
import { LegalSearchFormType } from "../../../entities/Types/LegalSearchForm";
import { stateListArray } from "../../../utils/constants/StateListConstant";
import { useEffect, useRef, useState } from "react";
import styles from '../legalSearch.module.css';
import { CountyListData, getCountyByStateCode } from "../../apn-search/getCounty/getCountySlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import LoadingProgressModel from "../../../components/ui/circularProgress/LoadingProgress";
import { resetLegalSearchState } from "./LegalSearchSlice";
import { LEGAL_SEARCH_FORM, LEGAL_SEARCH_TAB } from "../../../utils/constants/tabConstants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LegalSearchFormErrorMessages } from "../../../utils/constants/constants";
import { areAllPropertiesEmpty, specialCharacterFormatting, validateLotFromAndTo } from "../../../utils/common/commonUtils";
import { stateParameter } from "../../../entities/ApiModel/CountyRequest";
import { getStateCountyList, StateCountyListData } from "../../../common/Slice/countyListSlice";

interface Props {
    raiseError: Function
    onSubmit: Function;
    onClear: Function,
    formFields: LegalSearchFormType
}

function LegalSearchForm({ onSubmit, onClear, formFields, raiseError }: Props) {
    const [countyList, setCounty] = useState<any>([]);
    const [isDisabled, setDisabled] = useState(true);
    const [isCountyDisabled, setCountyDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [stateValue, setStateValue] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [openCountyDropDown, setOpenCountyDropDown] = useState(false);
    const stateWiseCountyList = useAppSelector(StateCountyListData);
    const stateFieldClickRef = useRef(false);
    const countyRef = useRef<HTMLInputElement>(null);;
    const stateRef = useRef<HTMLInputElement>(null);
    const searchButtonClickRef = useRef(false);
    const stateFieldKeyPressed = useRef<string | null>(null);
    const rerenderRef = useRef(0);

    const defaultValues: DefaultValues<LegalSearchFormType> = {
        state: { id: "", label: "" },
        fips: { county: "", fips: "" },
        subdivision_lot_from: "",
        subdivision_lot_to: "",
        block: "",
        subdivision: "",
        phase: "",
        condominium_building: "",
        condominium_unit_from: "",
        condominium_unit_to: "",
        condominium: "",
        volume: "",
        book: "",
        page: "",
        section: "",
        township: "",
        range: "",
        abstract_name: "",
        abstract_number: "",
        quarter1: "",
        quarter2: "",
        quarter3: ""
    };
    let messageRequired = "Required"


    useEffect(() => {
        if (!formFields || formFields?.state?.id === "" || !formFields?.fips?.fips) {
            setDisabled(true);
            setCountyDisabled(true);
        }
    }, []);

    const legalSearchValidationSchema = yup.object().shape({
        state: yup.object().shape({
            id: yup.string(),//.required(messageRequired),
            label: yup.string()//.required(messageRequired),
        }),
        fips: yup.object().shape({
            county: yup.string(),//.required(messageRequired),
            fips: yup.string()//.required(messageRequired),
        }),
        subdivision_lot_from: yup.string().max(50, LegalSearchFormErrorMessages.lotFromLength)
            .test('GreaterValueError', LegalSearchFormErrorMessages.LotFromGreaterValue, function (value) {
                let lotToValue: string = this.resolve(yup.ref("subdivision_lot_to"));
                let status = validateLotFromAndTo(specialCharacterFormatting(value), specialCharacterFormatting(lotToValue));
                if (status)
                    clearErrors("subdivision_lot_to");
                return status;
            })
            .test('StartWithSpecialCharacterError', LegalSearchFormErrorMessages.StartWithSpecialCharacter_Lot, function (value) {
                return value ? specialCharacterFormatting(value) ? true : false : true;
            }),
        subdivision_lot_to: yup.string().max(50, LegalSearchFormErrorMessages.lotToLength)
            .test('GreaterValueError', LegalSearchFormErrorMessages.LotFromGreaterValue, function (value) {
                let lotfromValue: string = this.resolve(yup.ref("subdivision_lot_from"));
                let status = validateLotFromAndTo(specialCharacterFormatting(lotfromValue), specialCharacterFormatting(value));
                if (status)
                    clearErrors("subdivision_lot_from");
                return status;
            })
            .test('StartWithSpecialCharacterError', LegalSearchFormErrorMessages.StartWithSpecialCharacter_Lot, function (value) {
                return value ? specialCharacterFormatting(value) ? true : false : true;
            }),
        block: yup.string().max(25, LegalSearchFormErrorMessages.blockLength),
        subdivision: yup.string().max(255, LegalSearchFormErrorMessages.subdivisionNameLength),
        phase: yup.string().max(25, LegalSearchFormErrorMessages.phaseNoLength),
        condominium_building: yup.string().max(25, LegalSearchFormErrorMessages.buildingNoLength),
        condominium_unit_from: yup.string().max(50, LegalSearchFormErrorMessages.unitFromLength)
            .test('GreaterValueError', LegalSearchFormErrorMessages.UnitFromGreaterValue, function (value) {
                let unitToValue: string = this.resolve(yup.ref("condominium_unit_to"));
                let status = validateLotFromAndTo(specialCharacterFormatting(value), specialCharacterFormatting(unitToValue));
                if (status)
                    clearErrors("condominium_unit_to");
                return status;
            })
            .test('StartWithSpecialCharacterError', LegalSearchFormErrorMessages.StartWithSpecialCharacter_Unit, function (value) {
                return value ? specialCharacterFormatting(value) ? true : false : true;
            }),
        condominium_unit_to: yup.string().max(50, LegalSearchFormErrorMessages.unitToLength)
            .test('GreaterValueError', LegalSearchFormErrorMessages.UnitFromGreaterValue, function (value) {
                let unitfromValue: string = this.resolve(yup.ref("condominium_unit_from"));
                let status = validateLotFromAndTo(specialCharacterFormatting(unitfromValue), specialCharacterFormatting(value));
                if (status)
                    clearErrors("condominium_unit_from");
                return status;
            })
            .test('StartWithSpecialCharacterError', LegalSearchFormErrorMessages.StartWithSpecialCharacter_Unit, function (value) {
                return value ? specialCharacterFormatting(value) ? true : false : true;
            }),
        condominium: yup.string().max(255, LegalSearchFormErrorMessages.condomiumLength),
        volume: yup.string().max(25, LegalSearchFormErrorMessages.volumeLength),
        book: yup.string().max(25, LegalSearchFormErrorMessages.bookLength),
        page: yup.string().max(25, LegalSearchFormErrorMessages.pageLength),
        section: yup.string().max(50, LegalSearchFormErrorMessages.sectionLength),
        township: yup.string().max(50, LegalSearchFormErrorMessages.townshipLength),
        range: yup.string().max(50, LegalSearchFormErrorMessages.rangeLength),
        abstract_name: yup.string().max(255, LegalSearchFormErrorMessages.abstractNameLength),
        abstract_number: yup.string().max(15, LegalSearchFormErrorMessages.abstractNoLength),
        quarter1: yup.string().max(15, LegalSearchFormErrorMessages.q1Length),
        quarter2: yup.string().max(15, LegalSearchFormErrorMessages.q2ToLength),
        quarter3: yup.string().max(15, LegalSearchFormErrorMessages.q3Length),
    });
    const form = useForm<LegalSearchFormType>({
        defaultValues,
        resolver: yupResolver(legalSearchValidationSchema)
    });
    const dispatch = useAppDispatch();
    const countyListState = useAppSelector(CountyListData);

    const { register, handleSubmit, setValue, resetField, reset, watch, getValues, clearErrors, setError, trigger, formState: { errors }, control } = form;

    const handleSubmitClick = (formData: LegalSearchFormType) => {
        searchButtonClickRef.current = true;
        if (!formData.state.id &&
            !formData.fips?.fips) {
            setError("state.id", { type: "Required", message: messageRequired });
            setError("fips.fips", { type: "Required", message: messageRequired });
            if (stateRef.current) {
                stateRef.current.focus(); // Focus the input
            }
            return;
        }
        if (!formData.fips?.fips) {
            setError("fips.fips", { type: "Required", message: messageRequired });
            if (countyRef.current) {
                countyRef.current.focus(); // Focus the input
            }
            return;
        }

        if (!formData.subdivision_lot_from?.trim() &&
            !formData.subdivision_lot_to?.trim() &&
            !formData.block?.trim() &&
            !formData.subdivision?.trim() &&
            !formData.phase?.trim() &&
            !formData.condominium_building?.trim() &&
            !formData.condominium_unit_from?.trim() &&
            !formData.condominium_unit_to?.trim() &&
            !formData.condominium?.trim() &&
            !formData.volume?.trim() &&
            !formData.book?.trim() &&
            !formData.page?.trim() &&
            !formData.section?.trim() &&
            !formData.township?.trim() &&
            !formData.range?.trim() &&
            !formData.abstract_name?.trim() &&
            !formData.abstract_number?.trim() &&
            !formData.quarter1?.trim() &&
            !formData.quarter2?.trim() &&
            !formData.quarter3?.trim()) {
            setError("fieldValidationError", { type: "Empty_Form_Value", message: messageRequired });
            return;
        }

        // if (areAllPropertiesEmpty(formData, "state", "fips")) {
        //     setError("fieldValidationError", { type: "Empty_Form_Value", message: messageRequired });
        //     return;
        // }

        if (formData.subdivision_lot_from && !formData.subdivision_lot_to) {
            setValue("subdivision_lot_to", formData.subdivision_lot_from);
        }

        if (!formData.subdivision_lot_from && formData.subdivision_lot_to) {
            setValue("subdivision_lot_from", formData.subdivision_lot_to);
        }

        if (formData.condominium_unit_from && !formData.condominium_unit_to) {
            setValue("condominium_unit_to", formData.condominium_unit_from);
        }

        if (!formData.condominium_unit_from && formData.condominium_unit_to) {
            setValue("condominium_unit_from", formData.condominium_unit_to);
        }

        formData.tabName = LEGAL_SEARCH_FORM;
        onSubmit(formData);
    }

    // const getCountyList = (value: any) => {
    //     if (value && value.id) {
    //         setCounty([]);
    //         resetField("fips")
    //         const state = value.id.trim().toUpperCase();
    //         setSelectedState(state);
    //         let param: stateParameter = {
    //             stateCode: state,
    //             pageName: LEGAL_SEARCH_TAB
    //         }
    //         dispatch(getCountyByStateCode(param))
    //         clearErrorOnFields();
    //     }
    // }

    const resetFormFields = () => {
        setValue("fips", { county: "", fips: "" });
        setValue("subdivision_lot_to", "");
        setValue("subdivision_lot_from", "");
        setValue("block", "");
        setValue("subdivision", "");
        setValue("phase", "");
        setValue("condominium_building", "");
        setValue("condominium_unit_from", "");
        setValue("condominium_unit_to", "");
        setValue("condominium", "");
        setValue("volume", "");
        setValue("book", "");
        setValue("page", "");
        setValue("section", "");
        setValue("township", "");
        setValue("range", "");
        setValue("abstract_name", "");
        setValue("abstract_number", "");
        setValue("quarter1", "");
        setValue("quarter2", "");
        setValue("quarter3", "");
    }


    const clearErrorOnFields = () => {
        clearErrors("fieldValidationError");
        clearErrors("fips");
        clearErrors("subdivision_lot_to");
        clearErrors("subdivision_lot_from");
        clearErrors("block");
        clearErrors("subdivision");
        clearErrors("phase");
        clearErrors("condominium_building");
        clearErrors("condominium_unit_from");
        clearErrors("condominium_unit_to");
        clearErrors("condominium");
        clearErrors("volume");
        clearErrors("book");
        clearErrors("page");
        clearErrors("section");
        clearErrors("township");
        clearErrors("range");
        clearErrors("abstract_name");
        clearErrors("abstract_number");
        clearErrors("quarter1");
        clearErrors("quarter2");
        clearErrors("quarter3");
    }

    // useEffect(() => {
    //     if (countyListState.status === "loading")
    //         setLoading(true);
    //     else
    //         setLoading(false)

    //     if (countyListState && countyListState.county.match_code === "1"
    //         && (selectedState || formFields?.state?.id) && !countyListState.county.isExecuting) {
    //         setCounty(countyListState.county.countyList[LEGAL_SEARCH_TAB]);
    //         if (formFields && formFields?.state?.id && formFields?.fips?.fips) {
    //             setDisabled(false);
    //         }
    //         rerenderRef.current += 1;
    //         setCountyDisabled(false);
    //         setStateValue(getValues("state.id"));
    //     }
    // }, [countyListState]);

    const setCountyList = (state: any) => {
        let countyList = stateWiseCountyList.stateCountyList[state.id];
        setCounty(countyList);
        setDisabled(false);
        setCountyDisabled(false);
        rerenderRef.current += 1;
        setStateValue(prev => getValues("state.id") ?? "");
    }

    useEffect(() => {
        // if (stateWiseCountyList.status === "loading")
        //     setLoading(true);
        // else
        //     setLoading(false);

        if (stateWiseCountyList && stateWiseCountyList.match_code && stateWiseCountyList.match_code === "1"
            && !stateWiseCountyList.isExecuting
        ) {
            let state = formFields?.state?.id ? formFields.state : getValues("state");
            if (state?.id) {
                setCountyList(state);
            }
        }
    }, [stateWiseCountyList]);

    const getCountyList = (state: any) => {
        if (state && state.id) {
            if (stateWiseCountyList && stateWiseCountyList.stateCountyList &&
                stateWiseCountyList.stateCountyList[state.id]
            ) {
                // setCounty([]);
                resetField("fips")
                stateFieldClickRef.current = true;
                setCountyList(state);
                setSelectedState(state);
                resetFormFields();
                clearErrorOnFields();
            }
            else {
                dispatch(getStateCountyList())
            }
        }
    }

    useEffect(() => {
        if (stateValue && !isCountyDisabled && stateFieldClickRef.current) {
            setOpenCountyDropDown(true);
            if (countyRef.current) {
                countyRef.current.focus(); 
            }
            stateFieldClickRef.current = false;
        }
    }, [isCountyDisabled, rerenderRef.current]);

    useEffect(() => {
        if (formFields
            && formFields?.state?.id !== ""
            && formFields?.fips?.fips !== "") {
            // reset(formFields);

            setValue("state", formFields.state);
            setValue("fips", formFields.fips);
            setValue("subdivision_lot_from", formFields.subdivision_lot_from);
            setValue("subdivision_lot_to", formFields.subdivision_lot_to);
            setValue("block", formFields.block);
            setValue("subdivision", formFields.subdivision);
            setValue("phase", formFields.phase);
            setValue("condominium_building", formFields.condominium_building);
            setValue("condominium_unit_from", formFields.condominium_unit_from);
            setValue("condominium_unit_to", formFields.condominium_unit_to);
            setValue("condominium", formFields.condominium);
            setValue("volume", formFields.volume);
            setValue("book", formFields.book);
            setValue("page", formFields.page);
            setValue("section", formFields.section);
            setValue("township", formFields.township);
            setValue("range", formFields.range);
            setValue("abstract_name", formFields.abstract_name);
            setValue("abstract_number", formFields.abstract_number);
            setValue("quarter1", formFields.quarter1);
            setValue("quarter2", formFields.quarter2);
            setValue("quarter3", formFields.quarter3);
        }
    }, [formFields]);

    useEffect(() => {
        // alert("Error Raised");
        raiseError(errors, form.getValues());
    }, [errors.state, errors.fips, errors.subdivision_lot_from, errors.subdivision_lot_to, errors.block,
    errors.subdivision, errors.phase, errors.condominium_building, errors.condominium_unit_from,
    errors.condominium_unit_to, errors.condominium, errors.volume, errors.book,
    errors.page, errors.section, errors.township, errors.range, errors.abstract_name,
    errors.abstract_number, errors.quarter1, errors.quarter2, errors.quarter3, errors.fieldValidationError]);


    const clearSearchResults = () => {
        searchButtonClickRef.current = false;
        reset(defaultValues);
        dispatch(resetLegalSearchState())
        onClear();
        setDisabled(true);
        setCountyDisabled(true);
    }

    const handleInputChange = (event: any, newInputValue: any) => {
        setInputValue(newInputValue);
    };

    const handleCountyChange = () => {
        setValue("subdivision_lot_to", "");
        setValue("subdivision_lot_from", "");
        setValue("block", "");
        setValue("subdivision", "");
        setValue("phase", "");
        setValue("condominium_building", "");
        setValue("condominium_unit_from", "");
        setValue("condominium_unit_to", "");
        setValue("condominium", "");
        setValue("volume", "");
        setValue("book", "");
        setValue("page", "");
        setValue("section", "");
        setValue("township", "");
        setValue("range", "");
        setValue("abstract_name", "");
        setValue("abstract_number", "");
        setValue("quarter1", "");
        setValue("quarter2", "");
        setValue("quarter3", "");
        setDisabled(false);
        subDivisionLotFrom.focus();
        clearErrorOnFields();
    }

    let subDivisionLotFrom: any;
    const subDivisionLotFromWatch = watch('subdivision_lot_from');
    const [subDivisionLotFromFocused, setsubDivisionLotFromFocused] = useState<boolean>(false);

    let subDivisionLotTo: any;
    const subDivisionLotToWatch = watch('subdivision_lot_to');
    const [subDivisionLotToFocused, setsubDivisionLotToFocused] = useState<boolean>(false);

    let block: any;
    const blockWatch = watch('block');
    const [blockFocused, setblockFocused] = useState<boolean>(false);

    let subDivision: any;
    const subDivisionWatch = watch('subdivision');
    const [subDivisionFocused, setsubDivisionFocused] = useState<boolean>(false);

    let phase: any;
    const phaseWatch = watch('phase');
    const [phaseFocused, setphaseFocused] = useState<boolean>(false);

    let condominiumBuilding: any;
    const condominiumBuildingWatch = watch('condominium_building');
    const [condominiumBuildingFocused, setcondominiumBuildingFocused] = useState<boolean>(false);

    let condominiumUnitFrom: any;
    const condominiumUnitFromWatch = watch('condominium_unit_from');
    const [condominiumUnitFromFocused, setcondominiumUnitFromFocused] = useState<boolean>(false);

    let condominiumUnitTo: any;
    const condominiumUnitToWatch = watch('condominium_unit_to');
    const [condominiumUnitToFocused, setcondominiumUnitToFocused] = useState<boolean>(false);

    let condominium: any;
    const condominiumWatch = watch('condominium');
    const [condominiumFocused, setcondominiumFocused] = useState<boolean>(false);

    let volume: any;
    const volumeWatch = watch('volume');
    const [volumeFocused, setvolumeFocused] = useState<boolean>(false);

    let book: any;
    const bookWatch = watch('book');
    const [bookFocused, setbookFocused] = useState<boolean>(false);

    let page: any;
    const pageWatch = watch('page');
    const [pageFocused, setpageFocused] = useState<boolean>(false);

    let section: any;
    const sectionWatch = watch('section');
    const [sectionFocused, setsectionFocused] = useState<boolean>(false);

    let township: any;
    const townshipWatch = watch('township');
    const [townshipFocused, settownshipFocused] = useState<boolean>(false);

    let range: any;
    const rangeWatch = watch('range');
    const [rangeFocused, setrangeFocused] = useState<boolean>(false);

    let abstractName: any;
    const abstractNameWatch = watch('abstract_name');
    const [abstractNameFocused, setabstractNameFocused] = useState<boolean>(false);

    let abstractNumber: any;
    const abstractNumberWatch = watch('abstract_number');
    const [abstractNumberFocused, setabstractNumberFocused] = useState<boolean>(false);

    let quarter1: any;
    const quarter1Watch = watch('quarter1');
    const [quarter1Focused, setquarter1Focused] = useState<boolean>(false);

    let quarter2: any;
    const quarter2Watch = watch('quarter2');
    const [quarter2Focused, setquarter2Focused] = useState<boolean>(false);

    let quarter3: any;
    const quarter3Watch = watch('quarter3');
    const [quarter3Focused, setquarter3Focused] = useState<boolean>(false);


    const handleFocus = (e: any) => {
        switch (e?.target?.id) {
            case "subdivision_lot_from": {
                setsubDivisionLotFromFocused(true);
                break;
            }
            case "subdivision_lot_to": {
                setsubDivisionLotToFocused(true);
                break;
            }
            case "block": {
                setblockFocused(true);
                break;
            }
            case "subdivision": {
                setsubDivisionFocused(true);
                break;
            }
            case "phase": {
                setphaseFocused(true);
                break;
            }
            case "condominium_building": {
                setcondominiumBuildingFocused(true);
                break;
            }
            case "condominium_unit_from": {
                setcondominiumUnitFromFocused(true);
                break;
            }
            case "condominium_unit_to": {
                setcondominiumUnitToFocused(true);
                break;
            }
            case "condominium": {
                setcondominiumFocused(true);
                break;
            }
            case "volume": {
                setvolumeFocused(true);
                break;
            }
            case "book": {
                setbookFocused(true);
                break;
            }
            case "page": {
                setpageFocused(true);
                break;
            }
            case "section": {
                setsectionFocused(true);
                break;
            }
            case "township": {
                settownshipFocused(true);
                break;
            }
            case "range": {
                setrangeFocused(true);
                break;
            }
            case "abstract_name": {
                setabstractNameFocused(true);
                break;
            }
            case "abstract_number": {
                setabstractNumberFocused(true);
                break;
            } case "quarter1": {
                setquarter1Focused(true);
                break;
            }
            case "quarter2": {
                setquarter2Focused(true);
                break;
            }
            case "quarter3": {
                setquarter3Focused(true);
                break;
            }
        }
    }
    const handleBlur = (e: any) => {

        switch (e?.target?.id) {
            case "subdivision_lot_from": {
                // trigger('subdivision_lot_from');
                setsubDivisionLotFromFocused(false);
                break;
            }
            case "subdivision_lot_to": {
                // trigger('subdivision_lot_to');
                setsubDivisionLotToFocused(false);
                break;
            }
            case "block": {
                // trigger('block');
                setblockFocused(false);
                break;
            }
            case "subdivision": {
                // trigger('subdivision');
                setsubDivisionFocused(false);
                break;
            }
            case "phase": {
                // trigger('phase');
                setphaseFocused(false);
                break;
            }
            case "condominium_building": {
                // trigger('condominium_building');
                setcondominiumBuildingFocused(false);
                break;
            }
            case "condominium_unit_from": {
                // trigger('condominium_unit_from');
                setcondominiumUnitFromFocused(false);
                break;
            }
            case "condominium_unit_to": {
                // trigger('condominium_unit_to');
                setcondominiumUnitToFocused(false);
                break;
            }
            case "condominium": {
                // trigger('condominium');
                setcondominiumFocused(false);
                break;
            }
            case "volume": {
                // trigger('volume');
                setvolumeFocused(false);
                break;
            }
            case "book": {
                // trigger('book');
                setbookFocused(false);
                break;
            }
            case "page": {
                // trigger('page');
                setpageFocused(false);
                break;
            }
            case "section": {
                // trigger('section');
                setsectionFocused(false);
                break;
            }
            case "township": {
                // trigger('township');
                settownshipFocused(false);
                break;
            }
            case "range": {
                // trigger('range');
                setrangeFocused(false);
                break;
            }
            case "abstract_name": {
                // trigger('abstract_name');
                setabstractNameFocused(false);
                break;
            }
            case "abstract_number": {
                // trigger('abstract_number');
                setabstractNumberFocused(false);
                break;
            } case "quarter1": {
                // trigger('quarter1');
                setquarter1Focused(false);
                break;
            }
            case "quarter2": {
                // trigger('quarter2');
                setquarter2Focused(false);
                break;
            }
            case "quarter3": {
                // trigger('quarter3');
                setquarter3Focused(false);
                break;
            }
        }
    }

    const handleKeyUp = (e: any) => {
        if (errors.fieldValidationError && e?.target?.value)
            clearErrors("fieldValidationError");

        const { key } = e;
        if (key === "Tab" || key === "Enter") {
            return;
        }

        let formData = form.getValues();
        if (
            searchButtonClickRef.current &&
            !formData.subdivision_lot_to?.trim() &&
            !formData.subdivision_lot_from?.trim() &&
            !formData.block?.trim() &&
            !formData.subdivision?.trim() &&
            !formData.phase?.trim() &&
            !formData.condominium_building?.trim() &&
            !formData.condominium_unit_from?.trim() &&
            !formData.condominium_unit_to?.trim() &&
            !formData.condominium?.trim() &&
            !formData.volume?.trim() &&
            !formData.book?.trim() &&
            !formData.page?.trim() &&
            !formData.section?.trim() &&
            !formData.township?.trim() &&
            !formData.range?.trim() &&
            !formData.abstract_name?.trim() &&
            !formData.abstract_number?.trim() &&
            !formData.quarter1?.trim() &&
            !formData.quarter2?.trim() &&
            !formData.quarter3?.trim()
        ) {
            setError("fieldValidationError", { type: "Empty_Form_Value", message: messageRequired });
        }
    }

    const handleStateFieldKeyDown = (event: React.KeyboardEvent) => {
        stateFieldKeyPressed.current = event.key; // Store the key
    };

    const handleStateFieldBlur = (e: any) => {
        if (stateFieldKeyPressed.current === "Tab") {
            if (!e.target.value) {
                return;
            }
            const matchedState = stateListArray.find((state) => state.label === e.target.value.toUpperCase());
            if (matchedState) {
                setValue("state", matchedState);
                clearErrors("state");
                getCountyList(matchedState);
            }
        }
        stateFieldKeyPressed.current = null; // Reset the ref
    }

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <Box>
                <form noValidate onSubmit={handleSubmit(handleSubmitClick)}>
                    <Grid container>
                        <Grid item xs={1}>
                            <GridItem sx={{ paddingLeft: "0px" }}>
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <StyledAutocomplete
                                            {...field}
                                            {...register("state")}
                                            options={stateListArray}
                                            disableClearable
                                            getOptionLabel={(item: any) => (item.label ? item.label : "")}
                                            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                            noOptionsText={"No state"}
                                            renderInput={(params) => (
                                                <StyledTextField
                                                    {...params}
                                                    inputRef={stateRef}
                                                    error={!!fieldState.error}
                                                    onBlur={handleStateFieldBlur}
                                                    onKeyDown={handleStateFieldKeyDown}
                                                    autoFocus={true}
                                                    label="State"
                                                    required
                                                    variant="outlined"
                                                    data-testid="StateField"
                                                />
                                            )}
                                            onChange={(_, data) => { field.onChange(data); getCountyList(data) }}
                                        />
                                    )}
                                />
                            </GridItem>
                        </Grid>
                        <Grid item xs={1.8}>
                            <GridItem>
                                <Controller
                                    name="fips"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <StyledAutocomplete
                                            {...field}
                                            disabled={isCountyDisabled}
                                            open={openCountyDropDown}
                                            onOpen={() => setOpenCountyDropDown(true)}
                                            onClose={() => setOpenCountyDropDown(false)}
                                            // openOnFocus
                                            {...register("fips")}
                                            options={countyList}
                                            disableClearable
                                            noOptionsText={"No such county"}
                                            getOptionLabel={(item: any) => (item.county ? item.county : "")}
                                            isOptionEqualToValue={(option: any, value: any) => option.fips === value.fips}
                                            inputValue={inputValue}
                                            onInputChange={handleInputChange}
                                            onChange={(_, data) => { field.onChange(data); handleCountyChange() }}
                                            renderInput={(params: any) => (
                                                <StyledTextField
                                                    {...params}
                                                    inputRef={countyRef}
                                                    error={!!fieldState.error}
                                                    label="County"
                                                    required
                                                    variant="outlined"
                                                    data-testid="CountyField"
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </GridItem>
                        </Grid>
                        <Grid item xs={0.4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <GridItem sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Typography sx={{ color: '#212121', fontSize: '14px', fontWeight: '600' }}>LOT</Typography>
                            </GridItem>
                        </Grid>
                        <Grid item xs={0.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('subdivision_lot_from')}
                                    error={errors.subdivision_lot_from && errors.subdivision_lot_from.message !== "" ? true : false}
                                    id="subdivision_lot_from"
                                    inputRef={input => {
                                        subDivisionLotFrom = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!subDivisionLotFromWatch || subDivisionLotFromFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="SubdivisionLotFromField"
                                    fullWidth label="From" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={0.1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <GridItem sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <RemoveIcon sx={{ color: '#7F7F7F', fontSize: 18 }} />
                            </GridItem>
                        </Grid>
                        <Grid item xs={.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('subdivision_lot_to')}
                                    error={errors.subdivision_lot_to && errors.subdivision_lot_to.message !== "" ? true : false}
                                    id="subdivision_lot_to"
                                    inputRef={input => {
                                        subDivisionLotTo = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!subDivisionLotToWatch || subDivisionLotToFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="SubdivisionLotToField"
                                    fullWidth label="To" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('block')}
                                    error={errors.block && errors.block.message !== "" ? true : false}
                                    id="block"
                                    inputRef={input => {
                                        block = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!blockWatch || blockFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="BlockField"
                                    fullWidth label="Block" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={4.3}>
                            <GridItem>
                                <StyledTextField
                                    {...register('subdivision')}
                                    error={errors.subdivision && errors.subdivision.message !== "" ? true : false}
                                    id="subdivision"
                                    inputRef={input => {
                                        subDivision = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!subDivisionWatch || subDivisionFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="SubdivisionField"
                                    fullWidth label="Subdivision" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem sx={{ paddingLeft: "0px" }}>
                                <StyledTextField
                                    {...register('phase')}
                                    error={errors.phase && errors.phase.message !== "" ? true : false}
                                    id="phase"
                                    inputRef={input => {
                                        phase = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!phaseWatch || phaseFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="PhaseField"
                                    fullWidth label="Phase" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('condominium_building')}
                                    error={errors.condominium_building && errors.condominium_building.message !== "" ? true : false}
                                    id="condominium_building"
                                    inputRef={input => {
                                        condominiumBuilding = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!condominiumBuildingWatch || condominiumBuildingFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="CondominiumBuildingField"
                                    fullWidth label="Building" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={0.4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <GridItem sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Typography sx={{ color: '#212121', fontSize: '14px', fontWeight: '600' }}>UNIT</Typography>
                            </GridItem>
                        </Grid>
                        <Grid item xs={0.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('condominium_unit_from')}
                                    error={errors.condominium_unit_from && errors.condominium_unit_from.message !== "" ? true : false}
                                    id="condominium_unit_from"
                                    inputRef={input => {
                                        condominiumUnitFrom = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!condominiumUnitFromWatch || condominiumUnitFromFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="CondominiumUnitFromField"
                                    fullWidth label="From" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={0.1} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <GridItem sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <RemoveIcon sx={{ color: '#7F7F7F', fontSize: 18 }} />
                            </GridItem>
                        </Grid>
                        <Grid item xs={0.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('condominium_unit_to')}
                                    error={errors.condominium_unit_to && errors.condominium_unit_to.message !== "" ? true : false}
                                    id="condominium_unit_to"
                                    inputRef={input => {
                                        condominiumUnitTo = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!condominiumUnitToWatch || condominiumUnitToFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="CondominiumUnitToField"
                                    fullWidth label="To" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={4.3}>
                            <GridItem>
                                <StyledTextField
                                    {...register('condominium')}
                                    error={errors.condominium && errors.condominium.message !== "" ? true : false}
                                    id="condominium"
                                    inputRef={input => {
                                        condominium = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!condominiumWatch || condominiumFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="CondominiumNameField"
                                    fullWidth label="Condominium" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem sx={{ paddingLeft: "0px" }}>
                                <StyledTextField
                                    {...register('volume')}
                                    error={errors.volume && errors.volume.message !== "" ? true : false}
                                    id="volume"
                                    inputRef={input => {
                                        volume = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!volumeWatch || volumeFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="VolumeField"
                                    fullWidth label="Volume" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('book')}
                                    error={errors.book && errors.book.message !== "" ? true : false}
                                    id="book"
                                    inputRef={input => {
                                        book = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!bookWatch || bookFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="BookField"
                                    fullWidth label="Book" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('page')}
                                    error={errors.page && errors.page.message !== "" ? true : false}
                                    id="page"
                                    inputRef={input => {
                                        page = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!pageWatch || pageFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="PageField"
                                    fullWidth label="Page" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={3.6}>

                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem sx={{ paddingLeft: "0px" }}>
                                <StyledTextField
                                    {...register('section')}
                                    error={errors.section && errors.section.message !== "" ? true : false}
                                    id="section"
                                    inputRef={input => {
                                        section = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!sectionWatch || sectionFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="SectionField"
                                    fullWidth label="Section" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('township')}
                                    error={errors.township && errors.township.message !== "" ? true : false}
                                    id="township"
                                    inputRef={input => {
                                        township = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!townshipWatch || townshipFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="TownshipField"
                                    fullWidth label="Township" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('range')}
                                    error={errors.range && errors.range.message !== "" ? true : false}
                                    id="range"
                                    inputRef={input => {
                                        range = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!rangeWatch || rangeFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="RangeField"
                                    fullWidth label="Range" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={3.6}>

                        </Grid>
                        <Grid item xs={5.6}>
                            <GridItem sx={{ paddingLeft: "0px" }}>
                                <StyledTextField
                                    {...register('abstract_name')}
                                    error={errors.abstract_name && errors.abstract_name.message !== "" ? true : false}
                                    id="abstract_name"
                                    inputRef={input => {
                                        abstractName = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!abstractNameWatch || abstractNameFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="AbstractNameField"
                                    fullWidth label="Abstract Name" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('abstract_number')}
                                    error={errors.abstract_number && errors.abstract_number.message !== "" ? true : false}
                                    id="abstract_number"
                                    inputRef={input => {
                                        abstractNumber = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!abstractNumberWatch || abstractNumberFocused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="AbstractNumberField"
                                    fullWidth label="Abstract Number" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={3.6}>

                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem sx={{ paddingLeft: "0px" }}>
                                <StyledTextField
                                    {...register('quarter1')}
                                    error={errors.quarter1 && errors.quarter1.message !== "" ? true : false}
                                    id="quarter1"
                                    inputRef={input => {
                                        quarter1 = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!quarter1Watch || quarter1Focused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="Quarter_1_Field"
                                    fullWidth label="Quarter 1" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('quarter2')}
                                    error={errors.quarter2 && errors.quarter2.message !== "" ? true : false}
                                    id="quarter2"
                                    inputRef={input => {
                                        quarter2 = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!quarter2Watch || quarter2Focused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="Quarter_2_Field"
                                    fullWidth label="Quarter 2" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={2.8}>
                            <GridItem>
                                <StyledTextField
                                    {...register('quarter3')}
                                    error={errors.quarter3 && errors.quarter3.message !== "" ? true : false}
                                    id="quarter3"
                                    inputRef={input => {
                                        quarter3 = input;
                                    }}
                                    InputLabelProps={{
                                        shrink: !!quarter3Watch || quarter3Focused
                                    }}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    disabled={isDisabled}
                                    data-testid="Quarter_3_Field"
                                    fullWidth label="Quarter 3" variant="outlined" />
                            </GridItem>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} >
                            <GridItem sx={{ textAlign: 'left' }}>
                                <GradientButton type="submit" size="large" data-testid="SearchButton" variant="contained" className={styles.buttonPadding} startIcon={<SearchIcon sx={{ fontSize: 25 }} />}>
                                    <Typography>Search</Typography>
                                </GradientButton>
                                <ClearButton type="reset" onClick={clearSearchResults} size="large" data-testid="ClearButton" className={styles.buttonPadding} sx={{ marginLeft: '23px' }} variant="outlined" startIcon={<ThemedClearIcon sx={{ fontSize: 25 }} />}>
                                    <Typography>Clear</Typography>
                                </ClearButton>
                            </GridItem>
                        </Grid>
                    </Grid>
                </form>
            </Box >
        </>
    )
}

export default LegalSearchForm